// eslint-disable-next-line consistent-default-export-name/default-export-match-filename
'use client'
import { ComponentType, use } from 'react'
import { NamespaceKeys, NestedKeyOf, NextIntlClientProvider } from 'next-intl'
import { getMessagesSlice } from './common'
import { useLocale } from '@/i18n/client'

function WithI18nProvider<PropsType extends {}, K extends NamespaceKeys<IntlMessages, NestedKeyOf<IntlMessages>>>(
  Component: ComponentType<PropsType>,
  key: K
) {
  const WrappedComponent: React.FC<PropsType> = function WrappedComponent(props: PropsType) {
    const locale = useLocale()
    if (!locale) return null
    const messages = use(getMessagesSlice(key, locale))
    return (
      <NextIntlClientProvider
        locale={locale}
        messages={messages}
        defaultTranslationValues={{ strong: (chunks) => <strong>{chunks}</strong> }}
      >
        <Component {...props} />
      </NextIntlClientProvider>
    )
  }
  WrappedComponent.displayName = `${Component.displayName ?? 'Unknown'}WithI18nProvider`
  return WrappedComponent
}

export default WithI18nProvider
