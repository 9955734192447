/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-unused-modules */
import { Locale } from '@/i18n/types'
import { NamespaceKeys, NestedKeyOf } from 'next-intl'
import { cache } from 'react'
import { get, type pick, set } from 'lodash'
import { getMessages } from '@/i18n'
const flatPick: typeof pick = (object: any, ...paths: string[]) => {
  const o: any = {}
  paths.forEach((path) => {
    set(o, path, get(object, path))
  })
  return o
}
export const getMessagesSlice = cache(
  async <K extends NamespaceKeys<IntlMessages, NestedKeyOf<IntlMessages>>>(path: K, locale: Locale) => {
    const all = await getMessages(locale)
    const res = flatPick(all, path)
    return {
      ...res,
      nextjs: {
        ...res.nextjs,
        core: all.nextjs.core,
        errors: all.nextjs.errors,
      },
    }
  }
)
